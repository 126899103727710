import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showBanner: false 
};

export const homeSlice = createSlice({
    name: "Home",
    initialState,
    reducers: {
        setShowBanner: (state, action) => {
            state.showBanner = action.payload;
        }
    }
});

export const {
    setShowBanner
} = homeSlice.actions;

export const selectShowBanner = (state) => state.home.showBanner;

export default homeSlice.reducer;
