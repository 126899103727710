import { createSlice } from "@reduxjs/toolkit";

// Utility Service
import { getCartProductCookies, getAddOnsCookies } from '../utils/AuthService';

const initialState = {
    cartProduct: getCartProductCookies() || [],
    cartProductLoading: false,
    addOns: getAddOnsCookies() || [],
    subTotal: 0.0,
    addOnsTotal: 0.0,
    voucher: null,
    shippingFee: 0
}

export const cartSlice = createSlice({
    name: "Cart",
    initialState,
    reducers: {
        setCartProduct: (state, action) => {
            state.cartProduct = action.payload;
        },
        setCartProductLoading: (state, action) => {
            state.cartProductLoading = action.payload;
        },
        setAddOns: (state, action) => {
            state.addOns = action.payload;
        },
        setSubTotal: (state, action) => {
            state.subTotal = action.payload;
        },
        setAddOnsTotal: (state, action) => {
            state.addOnsTotal = action.payload;
        },
        setVoucher: (state, action) => {
            state.voucher = action.payload;
        },
        setShippingFee: (state, action) => {
            state.shippingFee = action.payload;
        },
        clearCart: () => initialState
    }
});

export const {
    setCartProduct,
    setCartProductLoading,
    setAddOns,
    setSubTotal,
    setAddOnsTotal,
    setVoucher,
    setShippingFee,
    clearCart
} = cartSlice.actions;

export const selectCartProduct = (state) => state.cart.cartProduct;
export const selectCartProductLoading = (state) => state.cart.cartProductLoading;
export const selectAddOns = (state) => state.cart.addOns;
export const selectSubTotal = (state) => state.cart.subTotal;
export const selectAddOnsTotal = (state) => state.cart.addOnsTotal;
export const selectVoucher = (state) => state.cart.voucher;
export const selectShippingFee = (state) => state.cart.shippingFee;

export default cartSlice.reducer;
