import { configureStore } from "@reduxjs/toolkit";

import addressReducer from "./addressSlice";
import cartReducer from "./cartSlice";
import homeReducer from "./homeSlice";
import productFilterReducer from "./productFilterSlice";
import searchNewsReducer from "./searchNewsSlice";
import searchResultReducer from "./searchResultSlice";
import userReducer from "./userSlice";
import wishlistReducer from "./wishlistSlice";

export default configureStore({
    reducer: {
        address: addressReducer,
        home: homeReducer,
        cart: cartReducer,
        productFilter: productFilterReducer,
        searchNews: searchNewsReducer,
        search: searchResultReducer,
        user: userReducer,
        wishlist: wishlistReducer,
    }
});
