import { createSlice } from "@reduxjs/toolkit";

// Utility Service
import { getDefaultAddressCookies } from '../utils/AuthService';

export const addressSlice = createSlice({
    name: "Address",
    initialState: {
        defalutAddress: getDefaultAddressCookies() || null
    },
    reducers: {
        setDefaultAddress: (state, action) => {
            state.defalutAddress = action.payload;
        }
    }
});

export const {
    setDefaultAddress
} = addressSlice.actions;

export const selectDefaultAddress = (state) => state.address.defalutAddress;

export default addressSlice.reducer;
