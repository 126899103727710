import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    category: [],
    brand: [],
    size: [],
    priceRange: '',
    month: null,
    sort: ''
}

export const productFilterSlice = createSlice({
    name: "Product-Filter",
    initialState,
    reducers: {
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setBrand: (state, action) => {
            state.brand = action.payload;
        },
        setSize: (state, action) => {
            state.size = action.payload;
        },
        setPriceRange: (state, action) => {
            state.priceRange = action.payload;
        },
        setMonth: (state, action) => {
            state.month = action.payload;
        },
        setSort: (state, action) => {
            state.sort = action.payload;
        },
        clearProductFilter: () => initialState
    }
});

export const {
    setCategory,
    setBrand,
    setSize,
    setPriceRange,
    setMonth,
    setSort,
    clearProductFilter
} = productFilterSlice.actions;

export const selectCategory = (state) => state.productFilter.category;
export const selectBrand = (state) => state.productFilter.brand;
export const selectSize = (state) => state.productFilter.size;
export const selectPriceRange = (state) => state.productFilter.priceRange;
export const selectMonth = (state) => state.productFilter.month;
export const selectSort = (state) => state.productFilter.sort;

export default productFilterSlice.reducer;
