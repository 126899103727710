import Cookies from 'js-cookie'

// save the token and user into the local storage
export const setUserSession = (token, user) => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', JSON.stringify(token));
}

// save the default address into the local storage
export const setDefaultAddressCookies = (defaultAddress) => {
    localStorage.setItem('defaultAddress', JSON.stringify(defaultAddress));
}

// save the wishlist product into the local storage
export const setWishListProductCookies = (wishlistProduct) => {
    localStorage.setItem('wishlistProduct', JSON.stringify(wishlistProduct));
}

// save the wishlist calendar product into the local storage
export const setWishListCalendarProductCookies = (wishlistCalendarProduct) => {
    localStorage.setItem('wishlistCalendarProduct', JSON.stringify(wishlistCalendarProduct));
}

// save the cart product into the local storage
export const setCartProductCookies = (cartProduct) => {
    localStorage.setItem('cartProduct', JSON.stringify(cartProduct));
}

// save the addOns product into the local storage
export const setAddOnsCookies = (addOns) => {
    localStorage.setItem('addOns', JSON.stringify(addOns));
}

// save the refer product id with refered user into the cookie
export const setReferProductCookies = (refer) => {
    Cookies.set('refer', JSON.stringify(refer))
}

// update user session in local storage
export const updateUserSession = (user) => {
    localStorage.removeItem("user");
    localStorage.setItem('user', JSON.stringify(user));
}

// update token in local storage
export const updateToken = (token) => {
    localStorage.removeItem("token");
    localStorage.setItem('token', JSON.stringify(token));
}

// return the user data from the local storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  return null;
}
  
// return the token from the local storage
export const getToken = () =>  {
    const token = localStorage.getItem('token');
    if (token) return JSON.parse(token);
    return null;
}

// return the default address from the local storage
export const getDefaultAddressCookies = () =>  {
    const defaultAddress = localStorage.getItem('defaultAddress');
    if (defaultAddress) return JSON.parse(defaultAddress);
    return null;
}

// return wishlist product from the local storage
export const getWishListProductCookies = () =>  {
    const wishlistProduct = localStorage.getItem('wishlistProduct');
    if (wishlistProduct) return JSON.parse(wishlistProduct);
    return null;
}

// return wishlist calendar product from the local storage
export const getWishListCalendarProductCookies = () =>  {
    const wishlistCalendarProduct = localStorage.getItem('wishlistCalendarProduct');
    if (wishlistCalendarProduct) return JSON.parse(wishlistCalendarProduct);
    return null;
}

// return cart product from the local storage
export const getCartProductCookies = () =>  {
    const cartProduct = localStorage.getItem('cartProduct');
    if (cartProduct) return JSON.parse(cartProduct);
    return null;
}

// return addOns product from the local storage
export const getAddOnsCookies = () =>  {
    const addOns = localStorage.getItem('addOns');
    if (addOns) return JSON.parse(addOns);
    return null;
}

// return the refer product id with refered user into the cookie
export const getReferProductCookies = () => {
    const refer = Cookies.get('refer');
    if (refer) return JSON.parse(refer);
    return null
}

// remove cart & addOns product from the local storage. also remove refer object from cookie
export const removeCart = () => {
    localStorage.removeItem("cartProduct");
    localStorage.removeItem("addOns");
    Cookies.remove('refer')
}
  
// remove the token, user, default address, wishlist, cart & addOns product from the local storage. also remove refer object from cookie
export const removeUserSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("defaultAddress");
    localStorage.removeItem("wishlistProduct");
    localStorage.removeItem("wishlistCalendarProduct");
    localStorage.removeItem("cartProduct");
    localStorage.removeItem("addOns");
    Cookies.remove('refer')
}